import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import ReactFusioncharts from "react-fusioncharts";
import React from 'react';

// Resolves charts dependancy
charts(FusionCharts);

const dataSource = {
  chart: {
    caption: "Total Jobs, Candidates, Interviews",
    // plottooltext: "<b>$percentValue</b> of web servers run on $label servers",
    showlegend: "1",
    showpercentvalues: "1",
    legendposition: "bottom",
    usedataplotcolorforlabels: "1",
    enableMultiSlicing: "0",
    bgColor: "#DDDDDD",
    bgAlpha: "50",
    borderRadius: "8",
    showBorder: "1",
    borderColor: "#DDDDDD",
    decimals: "0",
    theme: "fusion",
    // exportEnabled: "1",
    // exportFileName: "export_file1",
  },
  data: [
    {
      label: "Jobs",
      value: "32647479",
      color: "#7B54E4",
    },
    {
      label: "Candidates",
      value: "22100932",
      color: "#0bc26f",
    },
    
    {
      label: "Interviews",
      value: "18674221"
    }
  ]
};

class PieChart extends React.Component {
  
  render() {
    return (
      <ReactFusioncharts
          
         type="pie2d"

        width="100%"
        height="40%"
         dataFormat="JSON"
         
        dataSource={dataSource}
      />
    );
  }
}

export default PieChart;