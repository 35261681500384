import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import ReactFusioncharts from "react-fusioncharts";
import React from 'react';

// Resolves charts dependancy
charts(FusionCharts);

const dataSource = {
  chart: {
    caption: "Jobs & Candidates",
    captionAlignment: "left",
    subcaption: "2019-2020",
    xaxisname: "Years",
    // yaxisname: "Total number of apps in store",
    formatnumberscale: "1",
    bgColor: "#DDDDDD",
    bgAlpha: "50",
    borderRadius: "8",
    showBorder: "1",
    borderColor: "#DDDDDD",
    plottooltext:
      "<b>$dataValue</b> apps were available on <b>$seriesName</b> in $label",
    theme: "fusion",
    drawcrossline: "1"
  },
  categories: [
    {
      category: [
        {
          label: "November"
        },
        {
          label: "December"
        },
        {
          label: "January"
        },
        {
          label: "February"
        },
        {
          label: "March"
        }
      ]
    }
  ],
  dataset: [
    {
      seriesname: "Total Jobs",
      data: [
        {
          value: "500"
        },
        {
          value: "740"
        },
        {
          value: "1200"
        },
        {
          value: "600"
        },
        {
          value: "1600"
        }
      ]
    },
    {
      seriesname: "Jobs Applied By Candidates",
      data: [
        {
          value: "450"
        },
        {
          value: "630"
        },
        {
          value: "900"
        },
        {
          value: "850"
        },
        {
          value: "1350"
        }
      ]
    },
    {
      seriesname: "Selected Candidates",
      data: [
        {
          value: "425"
        },
        {
          value: "560"
        },
        {
          value: "760"
        },
        {
          value: "780"
        },
        {
          value: "1130"
        }
      ]
    }
  ]
};

class CombinedChart extends React.Component {
  render() {
    return (
      <ReactFusioncharts
        type="mscolumn2d"
        width="100%"
        height="40%"
        dataFormat="JSON"
        dataSource={dataSource}
      />
    );
  }
}

export default CombinedChart;