import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import { Container, Grid, Checkbox } from '@material-ui/core';
import 'typeface-roboto';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      maxWidth: '100%',
      flexDirection: 'row',

      paddingTop: 10,
      paddingBottom: 50,
      backgroundColor: '#ffffff',

    },

    summaryContainer: {


      backgroundColor: '#f7f7f7',
      paddingBottom: 80,
    },





    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },


    actionIconsStyle: {

      backgroundColor: '#e2e0e0',
    },

    mainCard: {

      paddingTop: 50,
      paddingBottom: 30,
      backgroundColor: '#f7f7f7',
      boxShadow: 'none',
    },

    avatar: {
      backgroundColor: red[500],
    },
  }),
);

export const Summary = () => {
  const classes = useStyles({})
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (

    <Card className={classes.mainCard}>
      <Container maxWidth="lg" className={classes.summaryContainer}>
        <Grid container spacing={2} >
          <Grid item lg={3} xs={12}>
            <Typography variant="h5">
              Summary
                </Typography>
          </Grid>
          <Grid item lg={12} xs={12}>
            <Typography variant="body2" align="justify" gutterBottom>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas ullamcorper mauris non tempus finibus.
              In et risus imperdiet, faucibus dolor sed, laoreet lorem. Suspendisse porttitor ligula a diam accumsan, eget laoreet lectus congue.
              Fusce ut ipsum sed mauris dictum sodales.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas ullamcorper mauris non tempus finibus.
              In et risus imperdiet, faucibus dolor sed, laoreet lorem. Suspendisse porttitor ligula a diam accumsan, eget laoreet lectus congue.
              Fusce ut ipsum sed mauris dictum sodales.
      
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas ullamcorper mauris non tempus finibus.
              In et risus imperdiet, faucibus dolor sed, laoreet lorem. Suspendisse porttitor ligula a diam accumsan, eget laoreet lectus congue.
              Fusce ut ipsum sed mauris dictum sodales.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas ullamcorper mauris non tempus finibus.
              In et risus imperdiet, faucibus dolor sed, laoreet lorem. Suspendisse porttitor ligula a diam accumsan, eget laoreet lectus congue.
              Fusce ut ipsum sed mauris dictum sodales.
              In et risus imperdiet, faucibus dolor sed, laoreet lorem. Suspendisse porttitor ligula a diam accumsan, eget laoreet lectus congue.
                </Typography>
          </Grid>
        </Grid>
      </Container>
    </Card>



  );
}