import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import { Business } from '@material-ui/icons';
import CakeIcon from '@material-ui/icons/Cake';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import SchoolIcon from '@material-ui/icons/School';
import React, { FunctionComponent } from 'react';
import men from "./../../assets/men.png";
import { getvalue } from '../../Helper';




interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    //   marginTop: 300,
    paddingBottom: 60,
    borderRadius: 15,
    width: '100%',
    boxShadow: 'none',


  },




  iconStyle: {

    color: '#FD8635',
    fontSize: '40',

  },

  iconText: {
    fontSize: '5',

  },

  avatarStyle: {

    display: 'flex',
    '& > *': {
      margin: theme.spacing(0.3),
      width: 25,
      height: 25,

    }
  },

  firstMen: {

    marginLeft: 70,
  },
  AppBarStyle: {
    backgroundColor: '#ffffff',
    color: '#000000',
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
    borderBottom: '1px solid #d3d1d1',
    boxShadow: 'none',
  },

  tabsStyle: {


    // marginLeft: 120,
    fontSize: 15,
    display: 'flex',

    justify: 'flex-end',
    alignItems: 'center',


  },

  TabPanelStyle: {
    borderRadius: 15,
  },

  tabMenu: {

    fontSize: 12,

  },

  tabHeading: {
    marginTop: 10,
    marginLeft: 20,
    marginRight: 50,
  },

  panelContainer: {

    paddingTop: 50,
  },

  main: {
    fontSize: 14,
    color: '#002448',
  },

  icons: {
    fontSize: 40,
  },


}));

export const TabsComponent: FunctionComponent<{ profile?: {}, members?: any[] }> = props => {
  const classes = useStyles({});
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.AppBarStyle}>

        <Tabs
          value={value}
          indicatorColor="primary"


          onChange={handleChange}
          aria-label="disabled tabs example"

        // className={classes.tabsStyle}

        >


          {/* <Typography variant="subtitle1" className={classes.tabHeading}>About</Typography> */}





          <Tab label="About" className={classes.main} />
          <Tab className={classes.tabMenu} label="" disabled />


          <Tab className={classes.tabMenu} label="Overview" />
          <Tab className={classes.tabMenu} label="Work" />






        </Tabs>
      </AppBar>
      <TabPanel value={value} index={4}>
        About
        </TabPanel>
      <TabPanel value={value} index={6}>
        Overview
        </TabPanel>
      <TabPanel value={value} index={7}>
        Work
        </TabPanel>


      <Grid container spacing={0} className={classes.panelContainer}>

        <Grid item lg={4} xs={12}>
          <List component="nav"  >
            <ListItem button>
              <ListItemIcon className={classes.iconStyle}>
                <Business className={classes.icons} />
              </ListItemIcon>
              <Typography variant="body2">
                in<br /> From {props.profile['address']}
              </Typography>
              {/* <ListItemText  primary="Email: kamranasif520@gmail.com" /> */}
            </ListItem>
          </List>
        </Grid>



        <Grid item lg={4} xs={12}>
          <List>
            <ListItem button>
              <ListItemIcon className={classes.iconStyle} >
                <CakeIcon className={classes.icons} />
              </ListItemIcon>
              <Typography variant="body2">
                Birthday<br /> {props.profile['birthday']}
              </Typography>
              {/* <ListItemText className={classes.iconText} primary="Web Page : www.google.com" /> */}
            </ListItem>
          </List>
        </Grid>


        <Grid item lg={4} xs={12}>
          <List>
            <ListItem button>
              <ListItemIcon className={classes.iconStyle}>
                <SchoolIcon className={classes.icons} />
              </ListItemIcon>
              <Typography variant="body2">
                Agent<br /> {getvalue(props.profile, 'employer.agent') ?? ''}
              </Typography>
              {/* <ListItemText className={classes.iconText} primary="Phone: 03400-3454-347" /> */}
            </ListItem>
          </List>
        </Grid>

        <Grid item lg={12} xs={12}>
          <List>
            <ListItem button>
              <ListItemIcon className={classes.iconStyle}>
                <PeopleAltIcon className={classes.icons} />
              </ListItemIcon>

              <Typography variant="body2">
                {props.members != null && props.members.length > 0 ? <div>{props.members.length} Member{props.members.length > 1 ? 's' : ''}</div> : <div>No Members Yet</div>}
              </Typography>

            </ListItem>
            <div className={classes.avatarStyle}>

              {props.members!=null && props.members.map((v,i)=>{
                if(i ==0){
                  return <img className={classes.firstMen} src={men} alt="placeholder image" />;
                }else{
                  return <img src={men} alt="placeholder image" />
                }
              })}
            </div>

          </List>
        </Grid>



      </Grid>











    </div>
  );
}
