import { useEffect, useState } from 'react';
import React from 'react';
import { Grid, Paper, CircularProgress, createStyles, Card, CardMedia, Button, Container, Box, Typography, Link, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { LocationOn, Payment, AccessTime } from '@material-ui/icons';
import Axios from 'axios';
import { apihost, API } from '../Helper';
import { EditableText, EditableSelect } from '../TComp';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import { createBrowserHistory } from 'history';
import lion from './../assets/lion.jpg'
import { SingleJobTitleBar } from './SingleJobTitleBar';
const useStyles = makeStyles({
    root: {
        backgroundColor: '#FBFBFB',

    },

    mainContainer: {

        backgroundColor: '#fbfbfb',
        padding: 50,
        minHeight: 600,
        borderRadius: 15,
        height: '100vh',
    },

   
    h2: {
        color: '#006999',
        fontWeight: 600,
        fontSize: 30,
        paddingTop: 10,
        padddingBottom: 20,
    },
    h3: {
        fontSize: 28,
        fontWeight: 600,
        paddingTop: 10,
        padddingBottom: 20,
    },
  
    companyLogo: {

        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: '#FBFBFB',
        width: 100,
        height: 100,
    },
    jobTitles: {

        paddingLeft: 20,
        paddingRight: 20, 

      
    },
    List: {
        '& .MuiListItem-root': {
            paddingTop: 3,
            paddingBottom: 3,
            '& .MuiListItemIcon-root': {
                minWidth: 25,
            },
        },

    },
    summary: {
        paddingTop: 30,
        '& textarea': {
            width: '100% !important',
        },
    },
    textarea: {
        width: '100%',
    },
    iconStyle: {

        color: '#b5adad',
      
},

});




export const AdminSingleJob: React.FunctionComponent = (props) => {
    const classes = useStyles(props);
    var ar = window.location.pathname.split('/');
    const [cJob, setJob] = useState();
    const [isEditing, setIsEditing] = useState(false);
    const [shortDes, setShortDes] = useState()
    const [longDes, setLongDes] = useState()
    const [salary, setSalary] = useState()
    const [time, setTime] = useState();
    const [location, setLocation] = useState();
    const [applicants, setApplicants] = useState([]);

    const { enqueueSnackbar } = useSnackbar()
    useEffect(() => {
        if (ar.length > 2) {
            Axios.get(apihost + '/job/' + ar[3]).then(x => {
                setLongDes(x.data['jobLongDescription'])
                setShortDes(x.data['jobShortDescription']);
                setSalary(x.data['salary']);
                setTime(x.data['time'])
                setLocation(x.data['locationText']);
                setJob(x.data);

                console.log(x.data['jobLongDescription']);
            })

            // Axios.get(apihost + '/employer/applicants', { headers: { job: ar[3] } }).then(x => {
            //     setApplicants(x.data);
            // })
        }
    }, []);
    if (ar.length < 3) {
        return <div>Invalid Url</div>
    } else if (cJob == null) {
        return <div >
            <CircularProgress style={{ marginLeft: '45%', marginRight: '45%', marginBottom: '200px', marginTop: '200px' }} />
        </div>
    }
    else {
        return <div>
            {/* <SingleJobTitleBar /> */}
        
        <Container maxWidth="xl" className={classes.mainContainer}>
          
                <Grid container>
                    <Grid item lg={1} xs={12}>
                            {/* <img src={cJob['companyLogo']} className={classes.companyLogo} /> */}
                            <img src={lion} alt="lion logo" className={classes.companyLogo} />
                       
                    </Grid>
                    <Grid item lg={4} className={classes.jobTitles} xs={12}>
                        <Typography variant="h6" component="h6" className={classes.h2}>{cJob['jobTitle']}</Typography>
                        <Link href={cJob['companyUrl']}>{cJob['companyName']}</Link>

                    </Grid>

                   
                    <Grid lg={4} xs={12}>

                        <List className={classes.List}>
                            <ListItem>
                                <ListItemIcon>
                                    <LocationOn className={classes.iconStyle} />
                                </ListItemIcon>
                                <ListItemText>
                                    {<EditableText text={location} onChange={x => { setLocation(x) }} isEditing={isEditing} />}

                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <Payment className={classes.iconStyle} />
                                </ListItemIcon>
                                <ListItemText>
                                    {<EditableText text={salary} onChange={x => { setSalary(x) }} isEditing={isEditing} />}
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <AccessTime className={classes.iconStyle} />
                                </ListItemIcon>
                                <ListItemText>
                                    {<EditableSelect value={time} onChange={x => { setTime(x) }} options={['Full Time', 'Part Time']} isEditing={isEditing} />}
                                </ListItemText>
                            </ListItem>
                        </List>

                    </Grid>
                    <Grid lg={2} xs={12}>
                        {!isEditing && <Button variant='outlined' color="primary" onClick={x => setIsEditing(true)}>Edit Job</Button>}
                        {isEditing && <Button variant='outlined'  onClick={async x => {
                            var j = cJob;
                            j['jobLongDescription'] = longDes;
                            j['jobShortDescription'] = shortDes;
                            j['time'] = time;
                            j['locationText'] = location;
                            j['salary'] = salary;

                            await Axios.put(apihost + '/job', j);
                            enqueueSnackbar("Changes Saved.")
                            setIsEditing(false);
                        }}>Save Changes</Button>}
                    </Grid>
                </Grid>
                
                    <Grid item lg={12}>
                        <div className={classes.summary}>
                            <Typography variant="h3" component="h3" className={classes.h3}>Short Description</Typography>
                            <EditableText wide text={shortDes} rows={2} onChange={x => { setShortDes(x) }} isEditing={isEditing} />

                            <Typography variant="h3" component="h3" className={classes.h3}> Description</Typography>
                            <EditableText wide text={longDes} rows={5} onChange={x => { setLongDes(x) }} isEditing={isEditing} />
                        </div>
                    </Grid>
                
         
                </Container>
                </div>
              
    }
}