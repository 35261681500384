import firebase from 'firebase';
import { bbc, AppEvent } from './Helper';

export const start = async () => {
      const firebaseConfig = {
            apiKey: "AIzaSyBsfLZ9RG2zOPVXAi1GCIHYsBd7tAP8cy4",
            authDomain: "jobland-server.firebaseapp.com",
            databaseURL: "https://jobland-server.firebaseio.com",
            projectId: "jobland-server",
            storageBucket: "jobland-server.appspot.com",
            messagingSenderId: "179196278817",
            appId: "1:179196278817:web:2897ec67d19d0e34bccb60",
            measurementId: "G-D5Y52QFVNH"
          };

      let fb = firebase.initializeApp({ messagingSenderId: "179196278817", projectId: 'jobland-server', apiKey: 'AIzaSyBsfLZ9RG2zOPVXAi1GCIHYsBd7tAP8cy4', appId: '1:179196278817:web:586bb60ba84eb885bccb60' });
      fb.messaging().onMessage(x => {
            bbc.emit(AppEvent.newMessageRecieved, x);
      })
      fb.messaging().onTokenRefresh((x) => {
            try {
                  console.log(x);
            } catch (er) {
                  console.log(er);
            }
      });
}

export function sendToTopic() {

}

export enum NotificationType {

}

