import { Button, List, ListItem } from "@material-ui/core";
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Collapse from '@material-ui/core/Collapse';
import { red } from '@material-ui/core/colors';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { AlternateEmail, Facebook, LinkedIn, Twitter } from '@material-ui/icons';
import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
    //   maxWidth: 345,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      
    },


    SocialIcons: {

            color: '#b5adad',


    },

    SocialText: {


        color: '#969191',
        textAlign: 'left',
    },

  


    media: {
      height: 50,
      width: 50,
      paddingTop: '56.25%', // 16:9
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },


    headings: {

        opacity: '70%',
    },
    avatar: {
      backgroundColor: red[500],
      width: 150,
      height: 150,
    },
  }),
);

export const CandidateProfile:FunctionComponent<{userdata:{}}> = props => {
  const classes = useStyles({})
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card className={classes.card} elevation={0} >
        <List>
        <ListItem alignItems="flex-start" >
      
      <Typography className={classes.headings} variant="h6">Profile</Typography>
      {/* {"8 minutes agos"} */}
 


</ListItem>
</List>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            R
          </Avatar>
        }
        //  action={
        // //   <IconButton aria-label="settings">
        // //     <MoreVertIcon />
        // //   </IconButton>
        //  }
        
        
      />

    <Typography className={classes.headings} variant="h6" >{props.userdata['name']}</Typography>
           <Typography variant="body2" >Product Designer/UX Coordinator</Typography>
    <Typography variant="caption" >{props.userdata['email']}</Typography><br />
          <Link to="/dashboard/chat">
           <Button variant="outlined" color="secondary" size="small">
                Message!
                </Button>
                </Link>
     {/* <CardMedia
        className={classes.media}
        image="./placeholder.png"
        title="Place holder"
      /> */}
      <CardContent>

      <List >

      <ListItem>
        
        <AlternateEmail className={classes.SocialIcons} />
        <Typography variant="body2" className={classes.SocialText}>     username@gmail.com</Typography>
          {/* <ListItemText primary="Vacation" secondary="July 20, 2014" /> */}
        </ListItem>



      <ListItem>
       
            <LinkedIn className={classes.SocialIcons} />
            <Typography variant="body2" className={classes.SocialText}>     Linkedin.com/in/213</Typography>
        
        {/* <ListItemText primary="Photos" secondary="Jan 9, 2014" /> */}
      </ListItem>
      <ListItem>
       
      <Twitter className={classes.SocialIcons} />
      <Typography variant="body2" className={classes.SocialText}>     twitter.name/com</Typography>
        {/* <ListItemText primary="Work" secondary="Jan 7, 2014" /> */}
      </ListItem>
      <ListItem>
        
      <Facebook className={classes.SocialIcons} />
      <Typography variant="body2" className={classes.SocialText}>     facebook.com/name</Typography>
        {/* <ListItemText primary="Vacation" secondary="July 20, 2014" /> */}
      </ListItem>


    

    </List>








        <Typography variant="body2" color="textSecondary" component="p">
         
        </Typography>
      </CardContent>
    





      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
        
        </CardContent>
      </Collapse>
    </Card>
  );
}