
import { delete_cookie, read_cookie, bake_cookie } from 'sfcookies';
import { createBrowserHistory } from 'history';
import * as firebase from 'firebase';
import { createProvider } from 'reactn';
import axios from 'axios';
import { EventEmitter } from 'events';


const webApi = 'https://jobland-server.uk.r.appspot.com';
const local = 'http://localhost:3002';
const server = 'http://34.71.74.232:8080';
let url = 'https://joblandsecurebackend.tk';

const heroku = 'https://jobland-api.herokuapp.com';

export let apihost = url;

export const setApiHost = (x: string) => {
      apihost = '';
}

export enum Lafz {
      Employer_Single_Page
}

export class LangHelper {

      static change = (lancode: string) => {
            bake_cookie('localdata', { lang: lancode })
            window.location.reload();
      }
      static lang = () => {
            var cook = read_cookie('localdata')
            if (cook['lang'] != null) return cook['lang']
            return 'en';
      }
      static cl = LangHelper.lang();

      static HomePageHelloText = LangHelper.cl == 'en' ? 'Hello There' : 'Ola';

      static get = (text: Lafz) => {
            var res = scrolls.find(x => x.text == text)
            return res[LangHelper.cl];
      }

}

const scrolls: { text: Lafz, fr: string, en: string }[] = [
      {
          text: Lafz.Employer_Single_Page,
          fr: 'Un petit flashback de Keiley!',
          en: 'A Little FlashBack Of Keiley!'
  
      }
  ]

export const getvalue = (obj, key) => {
      return key.split(".").reduce(function (o, x) {
            return (typeof o == "undefined" || o === null) ? o : o[x];
      }, obj);
}


export class AppEvent {
      static newChatSelected: string = 'newChatSelected'
      static newMessageRecieved: string = 'newMessageRecieved'
}

export const bbc = new EventEmitter();


export const logOut = () => {
      if (read_cookie('data') != null) {
            delete_cookie('data');
            createBrowserHistory().push('/');
            window.location.reload();
      }
}

export class API {

      static getCookieData() {
            return read_cookie('data');
      }

      static saveCookie(data: any) {
            bake_cookie('data', data);
      }

      static TryEmployerLogin = async (email: string, password: string) => {

            axios.post(apihost + '/employer/login', {
                  email: email,
                  password: password
            }).then(async x => {
                  x.data['email'] = email;
                  API.saveCookie(x.data);


                  let messaging = firebase.messaging();
                  let notificationPersmission = await Notification.requestPermission();

                  while (notificationPersmission != 'granted') {
                        let notificationPersmission = await Notification.requestPermission();

                        if (notificationPersmission != 'granted') {
                              alert('permission denied - DONT LET THIS HAPPEN');
                        } else {

                        }
                  }

                  await axios.post(apihost + '/employer/updateToken', { token: await messaging.getToken(), email: email }).then(x => {
                        alert(String(x.data))
                  });
                  createBrowserHistory().push(Ways.adminDashboard);
                  window.location.reload();
            })

      }
}




export const logourl = 'https://firebasestorage.googleapis.com/v0/b/jobland-app.appspot.com/o/logo.png?alt=media&token=515e8c5e-cf40-4669-9db8-e561c9da13cb';


export class Ways {

      static adminDashboard = '/dashboard'
      static adminEmployers: string = '/dashboard/employers';
      static adminJobListings: string = '/dashboard/jobs';
      static adminSingleJob = Ways.adminDashboard + '/job/'
      static adminCandidates = Ways.adminDashboard + '/candidates'
      static adminCandidateSinglePage = Ways.adminDashboard + '/candidate'
      static adminCompany = Ways.adminDashboard + '/company'
      static adminPostJob = Ways.adminDashboard + '/postJob'
      static adminpackages = Ways.adminDashboard + '/packages'
      static adminEmployerSinglePage = Ways.adminDashboard + '/employer/'



      static go = (x: string) => {
            createBrowserHistory().push(x);
            window.location.reload();
      }
}


