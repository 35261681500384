import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import ReactFusioncharts from "react-fusioncharts";
import React from 'react';

// Resolves charts dependancy
charts(FusionCharts);

const dataSource = {
  chart: {
    caption: "Candidates Per Month",
    captionAlignment: "left",
    Align: "left",
    // yaxisname: "Velocity (in mph)",
    subcaption: "[2019-2020]",
    numbersuffix: "",
    rotatelabels: "1",
    setadaptiveymin: "1",
    bgColor: "#DDDDDD",
    bgAlpha: "50",
    borderRadius: "8",
    showBorder: "1",
    borderColor: "#DDDDDD",
    theme: "fusion"
  },
  data: [
   
    {
      label: "June",
      value: "60"
    },
    {
      label: "July",
      value: "90"
    },
    {
      label: "August",
      value: "95"
    },
    {
      label: "September",
      value: "120"
    },
    {
      label: "October",
      value: "250"
    },
    {
      label: "November",
      value: "150"
    },
    {
      label: "December",
      value: "55"
    },
    {
      label: "Junaury",
      value: "90"
    },
    {
      label: "February",
      value: "75"
    },
    {
        label: "March",
        value: "205"
      }
  ]
};

class CandidatesChart extends React.Component {
  render() {
    return (
      <ReactFusioncharts
        type="line"
        width="100%"
        height="40%"
        dataFormat="JSON"
        dataSource={dataSource}
      />
    );
  }
}

export default CandidatesChart;