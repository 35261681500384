import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import { Container } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import blue_logo from './assets/blue_logo.png';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Jobland
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    
    backgroundColor: '#ffffff',
  },
  image: {
    // backgroundImage: 'url(https://source.unsplash.com/random)',
    
    background:
    'linear-gradient(to bottom, rgba(87, 83, 236) 40%, rgba(131, 84, 226) )',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(8,0,8,0),
  },
  avatar: {
    margin: theme.spacing(1),
    // backgroundColor: theme.palette.secondary.main,
    background:
    'linear-gradient(to bottom, rgba(87, 83, 236) 60%, rgba(131, 84, 226) )',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    background:
    'linear-gradient(to bottom, rgba(87, 83, 236) 60%, rgba(131, 84, 226) )',
  },

  logoImage: {
    
    marginLeft: '40%',
    marginTop: '35%',

      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
  },

 
}));

export const AdminSignUp = () => {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="sm" className={classes.root}>
    <CssBaseline />
    {/* <Grid item xs={false} sm={4} md={7} className={classes.image}>
        <img src={whitelogo} alt="sample image" className={classes.logoImage} />
        
    </Grid>
    <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square style={{backgroundColor: '#f7f7f7',}}> */}

        
      <div className={classes.paper}>
       {/* <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar> */}
        <img src={blue_logo} alt="top logo" />
        <br /> <br />
        
        <Typography component="h1" variant="h5">
          Sign Up
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="company"
            label="Company Name"
            name="company name"
           
            autoFocus
          />

              <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="industry"
            label="Industry"
            name="industry"
           
            autoFocus
          />

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="agent"
            label="Resource Agent Name"
            name="agent name"
           
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="phone"
            label="Phone"
            name="phone"
           
            autoFocus
          />

              <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="website"
            label="Company Website"
            name="website"
           
            autoFocus
          />


              <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />


          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Register
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="/" variant="body2">
              <p>Already have account? Login Here</p>
              </Link>
            </Grid>
            <Grid item>
              {/* <Link href="#" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link> */}
            </Grid>
          </Grid>
          {/* <Box mt={5}>
            <Copyright />
          </Box> */}
        </form>
      </div>
   </Container>
  );
}