import * as React from 'react';
import MaterialTable from 'material-table';
import axios from 'axios';
import { Button, IconButton, Icon, Dialog, List, ListItem, DialogContent, AppBar, Toolbar, Grid, Card, CircularProgress, colors, Breadcrumbs, Link, Typography } from '@material-ui/core';
import { Delete, Close, PanoramaFishEye, Visibility } from '@material-ui/icons';
import { SingleJob } from './SingleJob';
import { createBrowserHistory } from 'history';
import { apihost, Ways } from '../Helper';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import { Work } from '@material-ui/icons';

interface JobsTableProps {
}

export const FullScreenLoading: React.FunctionComponent = props => {
  return <div >
    <CircularProgress style={{ marginLeft: '45%', marginRight: '45%', marginBottom: '200px', marginTop: '200px', }} />
  </div>
}

export const JobsTable: React.FunctionComponent<JobsTableProps> = (props) => {

  let a: any[] = Array.of<any>();
  const [data, setData] = React.useState(a);
  const [isOpen, setIsOpne] = React.useState(false);
  const [cDoc, setCDoc] = React.useState({});
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = React.useState(true);


  const getData = async () => {
    setIsLoading(true);
    var d = await axios.get(apihost + '/jobs');
    console.log(d.data)
    setData(d.data['jobs']);
    setIsLoading(false);

  }
  React.useEffect(() => {
    getData();
  }, [])
  if (isLoading) {
    return <FullScreenLoading/>
  } else {
    return <div>
      
     
              <Grid container style={{backgroundColor: '#f7f7f7', borderTopLeftRadius: 8, borderTopRightRadius: 8, paddingTop: 20,}}>
                <Grid item lg={1}>   <IconButton edge="start" style={{ color: '#515054',}} color="inherit" aria-label="menu">
        <Work style={{fontSize: 35, marginLeft: 50,}} />
      </IconButton></Grid>
            <Grid item lg={2}><Typography variant="h4" style={{color: '#515054', marginTop: 10,}}>All Jobs</Typography></Grid>
            <Grid item lg={5}></Grid>
            <Grid item lg={3}>
              <List style={{display: 'flex', flexDirection: 'row',}}>
              <ListItem>
                 <Button
                 variant="outlined"
                 style={{backgroundColor: '#7853e4', color: '#fff'}}  onClick={async x => {
          setIsLoading(true);

          var d = await axios.get(apihost + '/jobs');
          console.log(d.data)
          setData(d.data['jobs']);
          setIsLoading(false);

        }}>All Jobs</Button>
        
        </ListItem>
        <ListItem>
        <Button variant="outlined"
        style={{backgroundColor: '#7853e4', color: '#fff'}}
        
         onClick={async x => {
          setIsLoading(true);

          var d = await axios.get(apihost + '/ourjobs');
          console.log(d.data)
          setData(d.data['jobs']);
          setIsLoading(false);

        }}>Registered</Button>
        </ListItem>
        </List>
        </Grid>
        </Grid>
      <MaterialTable
      style={{boxShadow: 'none', backgroundColor: '#F7F7F7', borderBottomLeftRadius: 8, borderBottomRightRadius: 8,}}
        title=''
      
        columns={[
          
          { title: 'Title', field: 'jobTitle' },
          { title: 'Company', field: 'companyName' },
          { title: 'Location', field: 'locationText' },
          { title: 'Summery', field: 'jobShortDescription' },
          { title: "Applicants", render :(data)=><div><Link href={Ways.adminSingleJob+data['_id'] + '/applicants'}>{data['applicants']}</Link></div>},
          {
            title: 'Actions', render: (doc) =>
              <List style={{display: 'flex', flexDirection: 'row',}}>
                <ListItem>
                <Button variant="outlined" style={{color: '#bc26f'}} size="small"
                 onClick={(x) => {
                  createBrowserHistory().push(Ways.adminSingleJob + doc['_id']);
                  window.location.reload();
                }}>View</Button>

                </ListItem>

                <ListItem style={{marginLeft: -20,}}>
                <Button variant="outlined" size="small" color="secondary" 
                 onClick={x => {
                  setCDoc(doc);
                  setIsOpne(true);
                  return;
                }}>
                  Delete
                </Button>
                </ListItem>
              </List>
              
               
            
          },
        ]}
        options={{
          pageSize: 20, pageSizeOptions: []
        }} data={data} />
      <Dialog open={isOpen}>
        <Card style={{ padding: "30px" }}>
          <p>Are you sure you want to delete?</p>
          <Grid>
            <ThemeProvider theme={createMuiTheme({
              overrides: {
                MuiButton: {
                  text: {
                    color: 'white'
                  }
                },
              }
            })}>
              <Button style={{ background: 'red', marginRight: '10px' }} onClick={async x => {
                await axios.delete(apihost + '/job', { headers: { jobid: cDoc['_id'] } }).then(x => {
                  if (x.status == 200) {
                    console.log(x.data);
                    var newData = data.filter(x => {
                      console.log(x['_id'] + '   ' + cDoc['_id']);
                      return x['_id'] != cDoc['_id']
                    });
                    setData(newData);
                  }
                });
                setIsOpne(false);
                enqueueSnackbar('Job Listing Deleted', { color: 'red' });
              }}>Delete</Button>
            </ThemeProvider>
            <Button variant="outlined" onClick={x => setIsOpne(false)}>Cancel</Button>
          </Grid>
        </Card>
      </Dialog>
    </div >
  }
};

