// STEP 1 - Include Dependencies
// Include react
import React from "react";
import ReactDOM from "react-dom";

// Include the react-fusioncharts component
import ReactFC from "react-fusioncharts";

// Include the fusioncharts library
import FusionCharts from "fusioncharts";

// Include the chart type
import Column2D from "fusioncharts/fusioncharts.charts";

// Include the theme as fusion
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";

// Adding the chart and theme as dependency to the core fusioncharts
ReactFC.fcRoot(FusionCharts, Column2D, FusionTheme);


// STEP 2 - Chart Data
const chartData = [
  
 
  {
    label: "September",
    value: "290",
    color: "#7953E4",
  },
  {
    label: "October",
    value: "180",
    color: "#7953E4",
  },
  {
    label: "November",
    value: "140",
    color: "#7B54E4",
  },
  {
    label: "December",
    value: "115",
    color: "#7B54E4",
  },
  {
    label: "January",
    value: "100",
    color: "#7B54E4",
  },
  {
    label: "February",
    value: "30"
  },
  {
    label: "March",
    value: "250"
  },
  // {
  //   label: "China",
  //   value: "30"
  // }
];

// STEP 3 - Creating the JSON object to store the chart configurations
const chartConfigs = {
  type: "column2d",
  width:"100%",
  height:"40%",
  // width: "1200",
  // height: "400", 
  dataFormat: "json", // Data type
  dataSource: {
    // Chart Configuration
    chart: {
      //Set the chart caption
    //   caption: "Countries With Most Oil Reserves [2017-18]",
      //Set the chart subcaption
    //   subCaption: "In MMbbl = One Million barrels",
      //Set the x-axis name
      // xAxisName: "Job Posted Per Month",
      caption: "Jobs Posted Per Month",
      captionAlignment: "left",
      subcaption: "[2019-2020]",
      //Set the y-axis name
      // yAxisName: "Reserves (MMbbl)",
      numberSuffix: "",
      //Set the theme for your chart
      theme: "fusion",
      bgColor: "#DDDDDD",
      bgAlpha: "50",
      borderRadius: "8",
    showBorder: "1",
    borderColor: "#DDDDDD",
    },
    // Chart Data
    data: chartData
  }
};

// STEP 4 - Creating the DOM element to pass the react-fusioncharts component
class BarChart extends React.Component {
  render() {
    return (<ReactFC {...chartConfigs} />);
  }
}

export default BarChart;