import React, { FunctionComponent, useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { SingleJobPage } from './pages/SingleJobPage';
import { EmployerLoginPage } from './pages/EmployerLoginPage';
import { Ways, setApiHost } from './Helper';
import { read_cookie } from 'sfcookies';
import { createBrowserHistory } from 'history';
import { Button } from '@material-ui/core';
import { apihost } from './Helper';
import {ProperAdminDashboard} from './AdminDashboard';
import { AdminLogin } from './AdminLogin';
import { AdminSignUp } from './AdminSignUp';




export const DevSettings: FunctionComponent = props => {
  const [host, setHost] = useState(apihost);
  return <div>
    <input value={host} onChange={x => {
      setHost(x.target.value)
    }} />
    <Button onClick={x => {
      setApiHost(host);
    }}>
      Save
    </Button>
  </div>
}

export const LoginChecker: FunctionComponent = x => {

  let cook: any[] = read_cookie('name');
  var path = window.location.pathname;
  var tryingLogin = path != '/login' ; //|| path != Ways.employerLogin;
  if (cook.length == 0 && !tryingLogin) {
    createBrowserHistory().push('/login');
    window.location.reload();
  }

  return <div></div>;
}

export const LoginChoicePage: FunctionComponent = () => {

  return <div>
    <Button variant="contained">Login as Admin</Button>
    <Button variant="contained" onClick={e => {
      //createBrowserHistory().push(Ways.employerLogin);
      window.location.reload();
    }}>Login as Employer</Button>
  </div>
}
const App: React.FC = () => {
  return (
    <Router>
      
      <Route path="/" exact component={AdminLogin} />
      <Route path="/signup" exact component={AdminSignUp} />
      
      <Route path="/dashboard" component={ProperAdminDashboard} />  
    
    
      <div style={{ textAlign: 'center' }}>ver: 22-10-20-heroku</div>

     
     
      
    </Router>
  );
}

export default App;
