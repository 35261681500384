import React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem, { ListItemProps } from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import DraftsIcon from '@material-ui/icons/Drafts';
import ShareIcon from '@material-ui/icons/Share';
import PhoneIcon from '@material-ui/icons/Phone';
import Grid from '@material-ui/core/Grid';
import puppy from "./../../assets/puppy.jpg";
import { Container, Paper } from '@material-ui/core';
import 'typeface-roboto';
import Typography from '@material-ui/core/Typography';
import baby from './../../assets/baby.jpg';
import cat from './../../assets/cat.jpg';
import zebra from './../../assets/zebra.jpg';
import girl from './../../assets/girl.jpg';
import meeting from './../../assets/meeting.jpg';
import home from './../../assets/home.jpg';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      // maxWidth: 360,
      // backgroundColor: theme.palette.background.paper,
    //   marginTop: 300,
      borderRadius: 15,
      paddingBottom: 20,
      paddingTop: 20,
      backgroundColor: '#fbfbfb',
    },

    ImgStyle: {

width: 100,
height: 100,


    },


    ImgStyle1: {

      width: 100,
      height: 100,
      borderTopLeftRadius: 10,
      marginLeft: 10,
      
      
          },

          ImgStyle2: {

            width: 100,
            height: 100,
            borderBottomLeftRadius: 10,
            marginLeft: 10,
            
            
                },

                  ImgStyle3: {

            width: 100,
            height: 100,
            borderBottomLeftRadius: 10,
            
            
                },


                ImgStyle4: {

                  width: 100,
                  height: 100,
                  borderTopRightRadius: 10,
                  marginLeft: -10,
                  
                  
                      },


                      ImgStyle5: {

                        width: 100,
                        height: 100,
                        borderBottomRightRadius: 10,
                        marginLeft: -10,
                        
                        
                            },
                            imageContainer: {

                              marginBottom: -4,
                            },

                            listIcon: {
                                  color: '#6c79c7',

                            },


                            headings: {
                                              color: '#797980',
                            },



  }),
);

function ListItemLink(props: ListItemProps<'a', { button?: true }>) {
  return <ListItem button component="a" {...props} />;
}

export const PhotoGallery = () => {
  const classes = useStyles({});

  return (
    // <div className={classes.root}>
   

    <Container className={classes.root}>
       <Typography variant="h6" className={classes.headings} noWrap>
            Photos <br /><br />
          </Typography>

   <Grid container justify="center" alignItems="center" className={classes.imageContainer}>
    {/* <Grid item xs={12} md={8} lg={7}> */}
    <Grid item  lg={4} xs={4} md={2}>
      
      
       
        <img src={baby} alt="simple image"  className={classes.ImgStyle1} />
      
      
    </Grid>

    {/* <Grid item xs={12} md={4} lg={5}> */}
    <Grid item  lg={4} xs={4} md={2}>
      

      <img src={cat} alt="simple image" className={classes.ImgStyle} />
   
     

    </Grid>


    <Grid item  lg={4} xs={4} md={2}>
      
     
      <img src={zebra} alt="simple image" className={classes.ImgStyle4} />

     

    </Grid>
    </Grid>

   





    <Grid container direction="row" justify="center" alignItems="center">
    {/* <Grid item xs={12} md={8} lg={7}> */}
    <Grid item  lg={4} xs={4} md={2}>
      
      
      
        <img src={girl} alt="simple image"  className={classes.ImgStyle2} />
        
      
    </Grid>

    {/* <Grid item xs={12} md={4} lg={5}> */}
    <Grid item  lg={4} xs={4} md={2}>
      
      
      <img src={home} alt="simple image" className={classes.ImgStyle} />
      
     

    </Grid>


    <Grid item  lg={4} xs={4} md={2}>
      
      
      <img src={meeting} alt="simple image" className={classes.ImgStyle5} />
      
     

    </Grid>
    </Grid>

   
<Grid container>
    <Grid item xs>
    <List component="nav" aria-label="main mailbox folders" className={classes.listIcon}>
        <ListItem button>
          <ListItemIcon>
            <ArrowRightAltIcon className={classes.listIcon} />
          </ListItemIcon>
          <ListItemText primary="Go To Gallery" />
          </ListItem>
          </List>

    </Grid>
    </Grid>

  








    </Container>



    // </div>
  );
}