import React from 'react';
import clsx from 'clsx';
import 'typeface-roboto';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {Paper , Hidden , IconButton, Link, Grid, Divider, List, ListItem , Container ,Badge } from '@material-ui/core';
import {    MainListItems } from './admin/MainListItems';
import {Search, Notifications, Settings, AddShoppingCart, Apps, CheckBoxOutlined, DateRange, MailOutline, AccountBoxOutlined, Menu  } from '@material-ui/icons';
import 'typeface-roboto';
import men from './assets/men.png';
import whitelogo from './assets/whitelogo.png';
import { LayoutCards } from './admin/LayoutCards';
import { RecentActivities } from './admin/RecentActivities';
import { AllCandidates, AllCandidatesForJob } from './components/common/AllCandidatesOfEmployer';
import { Ways } from './Helper';
import { JobsTable } from './components/JobsTable';
import { PostAJobPage, PostJob } from './pages/PostAJobPage';
import { EmployersList } from './components/EmployersList';
import { SingleEmployerPage } from './components/EmployerEditPage';
import { MyAccountLayout } from './components/MyAccount/MyAccountLayout';
import DonutChart from './components/common/DonutChart';
import BarChart from './components/common/BarChart';
import PieChart from './components/common/PieChart';
import LineChart from './components/common/LineChart';
import CandidatesChart from './components/common/CandidatesChart';
import CombinedChart from './components/common/CombinedChart';
import Map from './components/common/Map';
import AverageChart from './components/common/AverageCart';
import { AdminSingleJob } from './components/AdminSingleJob';
import { Candidatelayout } from './components/common/CandidateLayout';



function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Jobland
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 250;



const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    backgroundColor : '#ffffff',
    '&:ListItemButton': {

      backgroundColor: '#ffffff',
    },

  },

 


  toolbar: {
    paddingRight: 24,
     // keep right padding when drawer closed
  },
  toolbarIcon: {
      
    // background:
    // 'linear-gradient(to right, rgba(130, 84, 226), rgba(57, 83, 243) 70%)',
    backgroundColor:'#5753ec',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },

  userSection: {
    backgroundColor: '#5753ec',
    height: 150,
    marginBottom: 60,
    // background:
    //   'linear-gradient(to right, rgba(130, 84, 226), rgba(57, 83, 243) 70%)',
      borderBottom: '0.5px solid #ffffff',


  },

 
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#F7F7F7',
    color: '#6D6D6D',
    brderRadius: 7,
    // marginTop: '2%',
    // marginRight: '1%',
   boxShadow: 'none',
    width: '100%',
    
  //  position: 'absolute',

  
    
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
   
    width: `calc(94% - ${drawerWidth}px)`,
    marginRight: '3.5%',
    borderRadius: 7,
    marginTop: '1%',
   
    
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
    // [theme.breakpoints.down('md')]: {
    //   display: 'none',
    // },
    //additional 
    
 
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 2,
  },




  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    // backgroundColor: '#5753ec',
       background:
    'linear-gradient(to bottom, rgba(87, 83, 236) 60%, rgba(131, 84, 226) )',
    // marginLeft: -10,
    width: drawerWidth,


    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
      
    }),

  
  

  },
  drawerPaperClose: {
    overflowX: 'hidden',


    '& .makeStyles-logoImage-27': {
      display: 'none',
    },

    '& .makeStyles-userImage-26': {

      width: 40,
      height: 40,
      marginLeft: -2,
      marginTop: 100,
      border: '4px solid #ffffff',

    },

    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.down('xl')]: {
      width: theme.spacing(9),
      
    },

   

    

    userImage: {

        display: 'none',
    },



  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    backgroundColor: '#ffffff',
    padding: '1%',
    
    
  },

  container: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(4),
    backgroundColor: '#ffffff',
    
  },
  paper: {
    padding: theme.spacing(0),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    
  },
  fixedHeight: {
    height: 240,
  },

  menuText: {
    fontFamily: 'Helvetica',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 400,
    color: '#ffffff',
    '&:hover $MuiListItemButton': {
      backgroundColor: 'red',
    },

  },

  topIcons: {
    color: '#454545',
    paddingLeft: 12,
    paddingRight: 12,
   
  },

  accountImage: {
    maxWidth: 24,
    maxHeight: 24,
  },

  accountText: {
color: '#454545',
marginRight: 10,

  },

  accountTextFirst: {
    color: '#7f7b7b',
    marginRight: 3,
    
  },

  toolbarDivider: {

    border: '0.0em solid #efefef', 
    height: 64, width: 0.7,
    marginLeft: 10,
    marginRight:10,


  },


  leftIcons: {
      
   
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },

  userText: {

    color: '#ffffff',
  },

  userText2: {

    color: '#ffffff',
    fontSize: 12,
  },

  userImage: {

    borderRadius: 70,
    height: 72,
    width: 72,
    border: '3px solid #ffffff',
    marginLeft: '35%',
  },

  logoImage: {
    width: '60%',
    height: '60%',
    marginLeft: '20%',
  },
}));

export const ProperAdminDashboard = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

 


  var content = <div></div>
var p = window.location.pathname ;

  if(p ==Ways.adminCandidates){
    content = <AllCandidates/>
  }else if(p == Ways.adminDashboard || p == '/' || p == ''){
    content = <div>
      <Grid container spacing={2}>



        <Grid item lg={12} xs={12} md={6}>
          <Typography variant="h4" style={{color: '#565454'}}>Dashboard</Typography>
        </Grid>
        <Grid item lg={6} xs={12} md={6}>
          <CandidatesChart />
        </Grid>

        <Grid item lg={6} xs={12} md={6}>
        <BarChart />
        </Grid>

        <Grid item lg={8} xs={12} md={6}>
          <CombinedChart />
        </Grid>
         
      <Grid item xs={12} md={6} lg={4} >
     
      
      <PieChart />
     
    </Grid>

  

    <Grid item xs={12} md={12} lg={12}>
  
    <AverageChart />
    

</Grid>





      <Grid item xs={12} md={4} lg={12} style={{marginTop: '2%',}}>
    {/* <LayoutCards /> */}
    {/* <AllCandidates /> */}
     
    </Grid>

   

 
   
    <Grid item xs={12} md={4} lg={6}>
 
    
    </Grid>

    <Grid item xs={12} md={4} lg={12}>
   
    </Grid>
    </Grid>
    </div>
  }else if (p.includes(Ways.adminEmployerSinglePage)){
    content = <MyAccountLayout />
  }
  else if (p.includes(Ways.adminCandidateSinglePage)){
    content = <Candidatelayout />
  }
  else if (p.includes('dashboard/job') && p.includes('applicants')) {
    content = <AllCandidatesForJob />
  }
  else if (p.includes(Ways.adminSingleJob)){
    content = <AdminSingleJob />
  }
 
 
  else if (p ==  Ways.adminJobListings){
    content = <JobsTable/>
  }else if (p == Ways.adminPostJob){
    content = <PostJob/>
  }else if (p == Ways.adminpackages){
    content = <h2>Here will be packages</h2>
  }else if (p == Ways.adminCompany){
    content = <MyAccountLayout />
  }else if (p == Ways.adminEmployers){
    content = <EmployersList/>
  }
  



  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);


  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
           
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <Menu />
          </IconButton>

          <IconButton color="inherit"
          className={classes.leftIcons}>
               <DateRange />
             </IconButton>
             <IconButton color="inherit"
             className={classes.leftIcons}
             >
               <MailOutline />
             </IconButton>

             <IconButton color="inherit"
             className={classes.leftIcons}
             >
               <AccountBoxOutlined />
             </IconButton>
             <IconButton color="inherit"
             className={classes.leftIcons}
             >
             <CheckBoxOutlined />
             </IconButton>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
          </Typography>
          <IconButton color="inherit">
                <Typography variant="caption" className={classes.accountTextFirst}>Hi, </Typography>
                <Typography variant="caption" className={classes.accountText}>Sean</Typography>
               <img src={men} alt="placeholder image" className={classes.accountImage} />
               </IconButton>
               <Divider orientation="vertical" variant="fullWidth" className={classes.toolbarDivider}  />
          <IconButton color="inherit">
            <Badge badgeContent={4} color="secondary">
              <Notifications />
            </Badge>
          </IconButton>
              <Divider orientation="vertical" variant="fullWidth" className={classes.toolbarDivider}  />
              <IconButton color="inherit"
              >
                <Notifications />
              </IconButton>
              <Divider orientation="vertical" variant="fullWidth" className={classes.toolbarDivider}  />
              <IconButton color="inherit"
              >
                <Settings />
              </IconButton> 
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose,),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <Menu style={{color: '#ffffff'}} />
          </IconButton>
          </div>
          <List className={classes.userSection}>
            <ListItem>
            <img src={whitelogo} alt="logo image" className={classes.logoImage} />

            </ListItem>

            <ListItem>
            <img src={men} alt="user image" className={classes.userImage} />
              
              </ListItem>
            </List>
        {/* <Divider /> */}
        <List className={classes.menuText}><MainListItems /></List>
        {/* <Divider /> */}
        {/* <List className={classes.menuText}>{secondaryListItems}</List> */}
      </Drawer>

      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="xl" className={classes.container}>
          
            {content}
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}