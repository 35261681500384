import * as React from 'react';
import { TextField, makeStyles, Switch, TextareaAutosize, Typography, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { FunctionComponent } from 'react';
import { Label } from '@material-ui/icons';

const useStyles = makeStyles({
      underline: {
            "&&&:before": {
                  borderBottom: "none"
            },
            "&&:after": {
                  borderBottom: "none"
            }
      }
});

export const EditableText: React.FunctionComponent<{ readOnly?: boolean, wide?: boolean, text: string, onChange: (x: String) => void, isEditing: boolean, rows?: number }> = props => {
      const [text, setText] = React.useState(props.text);
      let classes = useStyles({});
      var isOn = props.isEditing;
      return <div>
            {isOn && <TextField variant='outlined' multiline rows={props.rows} rowsMax={10} style={props.wide ? { width: '80%' } : {}} aria-label="maximum width" defaultValue={text} onChange={e => {
                  setText(e.target.value)
                  if (props.onChange != null) {
                        props.onChange(e.target.value);
                  }
            }} />}
            {!isOn && <div style={{ whiteSpace: 'pre-wrap' }}>{text}</div>}
      </div>
}

export const EditableSelect: React.FunctionComponent<{ value?: any, isEditing: boolean, onChange?: (x) => void, options?: string[] }> = props => {

      const [currentItem, setCurrentItem] = React.useState(props.value);

      var isOn = props.isEditing;

      return <div>

            {isOn && <FormControl variant="outlined" >
                  {/* <InputLabel  id="demo-simple-select-outlined-label">
                        Age
        </InputLabel> */}
                  <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={currentItem}
                        onChange={x=>{
                              setCurrentItem(x.target.value)
                              props.onChange(x.target.value);
                        }}>
                        {props.options.map(x => <MenuItem value={x}>{x}</MenuItem>)}
                       
                  </Select>
            </FormControl>
            }

            {!isOn && <div style={{ whiteSpace: 'pre-wrap' }}>{props.value}</div>}


      </div>
}


export const EditableTextBox: FunctionComponent<{ text: string, onChange: (x: string) => void, label: string, isEditing: boolean }> = props => {
      let isOn = props.isEditing;
      return <div style={{ marginTop: '10px' }}>
            {isOn && <TextField label={props.label} value={props.text} variant='outlined' onChange={x => props.onChange(x.target['value'])}>
            </TextField>}
            {
                  !isOn && <p><strong>{props.label + ' : '}</strong>{props.text}</p>
            }
      </div>
}
