import Grid from '@material-ui/core/Grid';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { FunctionComponent } from "react";
import 'typeface-roboto';




const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    //   marginTop: 300,
    paddingBottom: 50,
    borderRadius: 15,
    width: '100%',
    paddingLeft: 20,
    paddingRight: 20,


  },

  paragraphGrid: {

    paddingTop: 30,
    textAlign: "justify",
  },

}));





export const Biography: FunctionComponent<{ profile?: {} }> = props => {
  const classes = useStyles({});

  return (






    <div className={classes.root}>



      <Grid container spacing={2} className={classes.paragraphGrid}>
        <Grid item xs={12} >
          <Typography variant="h6">
            Biography
                  </Typography>
          
          <br />
          <Typography variant="body2">
            {props.profile['bio'] ?? ''}
        </Typography>
        </Grid>
      </Grid>



    </div>



  )
}
