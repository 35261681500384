import React, { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { QuestionAnswer, Mail, DateRange, Casino, Dashboard, ShoppingCart, People, BarChart, Layers, WorkSharp, AccountBalanceSharp, Lock, ConfirmationNumber } from '@material-ui/icons';
import 'typeface-roboto';
import { makeStyles } from '@material-ui/core/styles';
import { Ways, logOut } from '../Helper';
import { Link } from '@material-ui/core';


const useStyles = makeStyles({

  iconStyle: {
    width: '100%',
    paddingRight: 25,
    borderTopRightRadius: 25,
    borderBottomRightRadius: 25,
    transition: 'background-color 0ms',





    '& .MuiListItem-button': {
      marginRight: 15,
      marginLeft: 5,
      transitionDuration: '0ms',
      transitionDelay: '0ms',


      '&:hover': {
        backgroundColor: '#b07ff9',
        borderTopRightRadius: 25,
        borderBottomRightRadius: 25,
        marginLeft: 2,
        paddingRight: 25,
      },

    },



  },



  icontext: {
    '&:hover': {

      marginLeft: 4,
    },


  },




});



export const menuBarItems: { path: string, title: string, icon: JSX.Element, onClick?: () => void }[] = [

  { path: Ways.adminDashboard, icon: <Dashboard style={{ color: '#ffffff', }} />, title: "Dashboard" },
  { path: Ways.adminCandidates, icon: <People style={{ color: '#ffffff' }} />, title: "Candidates" },
  { path: Ways.adminEmployers, icon: <People style={{ color: '#ffffff' }} />, title: "Employers" },
  { path: Ways.adminJobListings, icon: <WorkSharp style={{ color: '#ffffff' }} />, title: "Jobs" },


  { path: Ways.adminCompany, icon: <AccountBalanceSharp style={{ color: '#ffffff' }} />, title: "Company" },
  { path: Ways.adminPostJob, icon: <Layers style={{ color: '#ffffff' }} />, title: "Post Job" },
  { path: Ways.adminpackages, icon: <ConfirmationNumber style={{ color: '#ffffff' }} />, title: "Packages" },
  { path:'logoutpath' ,icon: <Lock style={{ color: '#ffffff' }} />, title: "Logout", onClick: () => alert("This will be used to logout") },

]
export const MainListItems: FunctionComponent = (props) => {
  const classes = useStyles(props);

  let path = window.location.pathname;


  return <div className={classes.iconStyle}>
    {menuBarItems.map((x, i) => {
      let isSelected = path == x.path;

      if (x.onClick != null) {
        return <ListItem button onClick={e => {

          if (x.onClick != null) {
            x.onClick();
          }
        }} style={isSelected ? {
          backgroundColor: '#946fe0',
          borderTopRightRadius: 25,
          borderBottomRightRadius: 25,
          marginLeft: 2,
          paddingRight: 25,
        } : {}}>
          <ListItemIcon>
            {x.icon}
          </ListItemIcon>
          <ListItemText primary={x.title } />
        </ListItem>
      }
      return <NavLink to={x.path}
        style={{ textDecoration: 'none', color: path == x.path ? 'white' : '#ffffff' }}>
        <ListItem button onClick={e => {

          if (x.onClick != null) {
            x.onClick();
          }
        }} style={isSelected ? {
          backgroundColor: '#946fe0',
          borderTopRightRadius: 25,
          borderBottomRightRadius: 25,
          marginLeft: 2,
          paddingRight: 25,
        } : {}}>
          <ListItemIcon>
            {x.icon}
          </ListItemIcon>
          <ListItemText primary={x.title } />
        </ListItem></NavLink>
    })}

  </div>
}
