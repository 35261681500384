import React, { createContext } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core';
import { purple } from '@material-ui/core/colors';
import { SnackbarProvider } from 'notistack';
import { observable, autorun } from 'mobx';
import { start } from './Start';

// const theme = createMuiTheme({
//   palette: { primary: purple }
// });





// Set loading to true.


ReactDOM.render(
  <SnackbarProvider maxSnack={4}>
    {/* <ThemeProvider theme={theme}> */}
        <App/>
    {/* </ThemeProvider> */}
  </SnackbarProvider>
  , document.getElementById('root'));
  serviceWorker.register();
  start()


