import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import ReactFusioncharts from "react-fusioncharts";
import React from 'react';

// Resolves charts dependancy
charts(FusionCharts);

const dataSource = {
  chart: {
    caption: "Average Jobs, Candidates, Interviews Per Day",
    yaxisname: "% of youth on this platform",
    // subcaption: "2012-2016",
    captionAlignment: "left",
    showhovereffect: "1",
    numbersuffix: "%",
    drawcrossline: "1",
    bgColor: "#DDDDDD",
    bgAlpha: "50",
    borderRadius: "8",
    showBorder: "1",
    borderColor: "#DDDDDD",
    plottooltext: "<b>$dataValue</b> were on $seriesName",
    theme: "fusion"
  },
  categories: [
    {
      category: [
        {
          label: "Monday"
        },
        {
          label: "Tuesday"
        },
        {
          label: "Wednesday"
        },
        {
          label: "Thursday"
        },
        {
          label: "Friday"
        },
        {
            label: "Saturday"
          },
          {
            label: "Sunday"
          }
      ]
    }
  ],
  dataset: [
    {
      seriesname: "Jobs",
      data: [
        {
          value: "12"
        },
        {
          value: "28"
        },
        {
          value: "31"
        },
        {
          value: "28"
        },
        {
          value: "68"
        },
        {
            value: "58"
          },
          {
            value: "78"
          }
      ]
    },
    {
      seriesname: "Candidates",
      data: [
        {
          value: "25"
        },
        {
          value: "28"
        },
        {
          value: "34"
        },
        {
          value: "42"
        },
        {
          value: "64"
        },
        {
            value: "74"
          },
          {
            value: "88"
          }
      ]
    },
    {
      seriesname: "Interviews",
      data: [
        {
          value: "20"
        },
        {
          value: "22"
        },
        {
          value: "27"
        },
        {
          value: "22"
        },
        {
          value: "29"
        },
        {
            value: "39"
          },
          {
            value: "49"
          },
          
      ]
    },
    // {
    //   seriesname: "Twitter",
    //   data: [
    //     {
    //       value: "18"
    //     },
    //     {
    //       value: "19"
    //     },
    //     {
    //       value: "21"
    //     },
    //     {
    //       value: "21"
    //     },
    //     {
    //       value: "24"
    //     }
    //   ]
    // }
  ]
};

class AverageChart  extends React.Component {
  render() {
    return (
      <ReactFusioncharts
        type="msline"
        width="100%"
        height="50%"
        dataFormat="JSON"
        dataSource={dataSource}
      />
    );
  }
}


export default AverageChart;