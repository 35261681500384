import React, { FunctionComponent, useState } from 'react';
import { WorkOutline } from '@material-ui/icons';
import { makeStyles, Theme, createStyles, Grid, TextField, Card, Button, FormControl, InputLabel, MenuItem, Select, Box, AppBar, Toolbar, IconButton, Container, Typography, CssBaseline, Link } from '@material-ui/core';
import Axios from 'axios';
import { apihost, API } from '../Helper';
import { useSnackbar } from 'notistack';
import { read_cookie } from 'sfcookies';
import { PostJobTitleBar } from './PostJobTitleBar';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';




const UseNewStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(2),
    // display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 30,
    

  },

  LoginContainer: {

    backgroundColor: '#F7F7F7',
   
    
    marginTop: -50,
    borderRadius: 15,

  },

  iconStyle: {
    fontSize: 35,

  },

  topBar: {

    backgroundColor: '#F7F7F7',
    boxShadow: 'none',
   
    alignItems: 'stretch',
   
},


  menuButton: {
    marginRight: theme.spacing(2),
    color: '#515054',
   
  },
  title: {
    flexGrow: 1,
  //   display: 'none',
  color: '#515054',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },

  link: {

    textDecoration: 'none',
  },

  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#7853e4',
  },
}));

export const PostJob = () => {
  const classes = UseNewStyles({});

  const { enqueueSnackbar } = useSnackbar();

  const [companyUrl, setCompanyUrl] = useState();
  const [jobTitle, setJobTitle] = useState();
  const [companyName, setCompanyName] = useState();
  const [companyLogo, setCompanyLogo] = useState()
  const [jobShortDescription, setShortDes] = useState();
  const [jobLongDescription, setLongDes] = useState();
  const [salary, setSalary] = useState();
  const [location, setLocation] = useState();
  const [time, setTime] = useState();
  const [vacancies, setVacancies] = useState();

  return (
    <div>
      {/* <PostJobTitleBar /> */}
    <Container  maxWidth="xl" className={classes.LoginContainer}>
 
      
    
      
      
      
      <div className={classes.paper}>
      <AppBar position="static" className={classes.topBar}>
    <Toolbar>
       <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
        <WorkOutline className={classes.iconStyle} />
      </IconButton>
      <Typography variant="h4" className={classes.title}>
        Post A Job
      </Typography>
      
    </Toolbar>
  </AppBar> 
      

        <ValidatorForm onSubmit={()=>{
            var empId = JSON.stringify(API.getCookieData()['id']);
            var a: string[] = [
              jobTitle, jobShortDescription, jobLongDescription, salary, time, vacancies
            ];

            if (!a.every(x => x != null)) return;
            var job = {
              jobTitle: jobTitle,
              jobShortDescription: jobShortDescription,
              jobLongDescription: jobLongDescription,
              locationText: location,
              salary: salary,
              time: time,
              vacancies: vacancies,
              userid: empId
            }
            Axios.post(apihost + '/employer/postajob', job).then(x => {
              console.log(JSON.stringify(x.data));
              enqueueSnackbar("Job Posted");
            })

        }} className={classes.form} noValidate>
             <TextValidator
                variant="outlined"
                margin="normal"
                required
                fullWidth
                value={jobTitle}
                id="jobtitle"
                // label="Email Address"
                name="Title"
                onChange={e => setJobTitle(e.target['value'])}
                label="Job Title"
                autoFocus
                validators={['required', 'matchRegexp:^[a-zA-Z0-9 ]*$']}
                errorMessages={['this field is required', 'Job Title is in an invalid format']}
              />
              <TextValidator
                variant="outlined"
                margin="normal"
                required
                fullWidth
                value={jobShortDescription}
                onChange={e => setShortDes(e.target['value'])}
                name="Short Description"
                // label="Password"
                type="text"
                id="text"
                label="Short Description"
                validators={['required', 'matchRegexp:^[a-zA-Z0-9 .,]+$']}
                errorMessages={['this field is required', 'Short Description is in an invalid format']}

              // autoComplete="current-password"
              />

              <TextValidator
                variant="outlined"
                id="outlined-multiline-static"
                multiline
                margin="normal"
                required
                fullWidth
                value={jobLongDescription}
                rows={4}
                onChange={e => setLongDes(e.target['value'])}
                name="Long Description"
                rowsMax={8}
                // label="Password"
                type="text"
                validators={['required', 'matchRegexp:^[a-zA-Z0-9 .,]+$']}
                errorMessages={['this field is required', 'Long Description is in an invalid format']}

                label="Long Description"
              // autoComplete="current-password"
              />






              <TextValidator
                variant="outlined"
                margin="normal"
                id='salary'
                value={salary}
                required
                fullWidth
                name="Sallery"
                placeholder={'$'}
                onChange={e => setSalary(e.target['value'])}
                // label="Password"
                type="text"
                label="Sallery"
                validators={['required', 'isNumber']}
                errorMessages={['this field is required', 'Salary is in an invalid format']}

              // autoComplete="current-password"
              />



              <TextValidator
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id='location'
                value={location}
                name="Location"
                onChange={e => setLocation(e.target['value'])}
                // label="Password"
                type="text"
                label="Location"
                validators={['required', 'matchRegexp:^[a-zA-Z0-9 ,]+$']}
                errorMessages={['this field is required', 'Location is in an invalid format']}

              // autoComplete="current-password"
              />


              <TextValidator
                id="outlined-select-currency-native"
                select
                label="Job Type"
                fullWidth
                required
                name='jobtype'
                margin="normal"
                variant="outlined"
                value={time}
                validators={['required']}
                errorMessages={['this field is required']}

                onChange={x => {
                  setTime(x.target['value'])
                }}
              >
                <MenuItem value={10}>Part Time</MenuItem>
                <MenuItem value={20}>Full Time</MenuItem>
              </TextValidator>

              <TextValidator
                id="outlined-select-currency-native"
                select
                label="Vacancies"
                onChange={e => setVacancies(e.target['value'])}
                fullWidth
                margin="normal"
                value={vacancies}
                name='vacancies'
                variant="outlined"
                required
                validators={['required']}
                errorMessages={['this field is required']}
              >
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={5}>5</MenuItem>
              </TextValidator>
          <Link href="/dashboard" className={classes.link} >
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={e => {
              
              }}
            >
              Post Job
          </Button>
          </Link>

        </ValidatorForm>
      </div>
  
    </Container>
    </div>
  );
}


const useStyles = makeStyles({
  postJobPage: {
    minHeight: '100vh',
    backgroundColor: '#FBFBFB',
    
    
    
  },
  Card: {
    padding: 50,
    marginTop: 30,
  },
  loginFormFields: {
    marginTop: 10,
    marginBottom: 10,
  },
  topSpace: {
    marginTop: 30,
  },
  h2: {
    fontWeight: 800,
    fontSize: 40,
  },

});



export class Job {
  host?: string;
  companyUrl?: string;
  companyName?: string;
  companyLogo?: string;
  jobUrl?: string;
  jobTitle?: string;
  jobShortDescription?: string;
  locationText?: string;
  jobLongDescription?: string;
  applyLink?: string;
  salary?: string;
  time?: string;
  vacancies?: string;
}


export const PostAJobPage: FunctionComponent = props => {
  const classes = useStyles(props);
  const { enqueueSnackbar } = useSnackbar();

  const [companyUrl, setCompanyUrl] = useState();
  const [jobTitle, setJobTitle] = useState();
  const [companyName, setCompanyName] = useState();
  const [companyLogo, setCompanyLogo] = useState()
  const [jobShortDescription, setShortDes] = useState();
  const [jobLongDescription, setLongDes] = useState();
  const [salary, setSalary] = useState();
  const [location, setLocation] = useState();
  const [time, setTime] = useState();
  const [vacancies, setVacancies] = useState();



  return <Container>

    <Grid
      container
      direction="column"
      alignItems="center"
      justify="center"
      className={classes.postJobPage}>
      {/* <Grid item lg={12}><Typography variant="h2" component="h2" className={classes.h2}>Post a job</Typography></Grid> */}
      <Grid item lg={8}>
        <Card className={classes.Card} elevation={0}>
          <Grid container>

            <Grid item lg={12}>
              <TextField variant='outlined' className={classes.loginFormFields} fullWidth label='Job Title' onChange={e => setJobTitle(e.target['value'])}></TextField>
            </Grid>
            <Grid item lg={12}>
              <TextField variant='outlined' className={classes.loginFormFields} fullWidth label='Short Description' onChange={e => setShortDes(e.target['value'])}></TextField>
            </Grid>
            <Grid item lg={12}>
              <TextField variant='outlined' className={classes.loginFormFields} fullWidth label='Long Description' onChange={e => setLongDes(e.target['value'])}></TextField>
            </Grid>
            <Grid item lg={12}>
              <TextField variant='outlined' className={classes.loginFormFields} fullWidth label='Salary' onChange={e => setSalary(e.target['value'])}></TextField>
            </Grid>
            <Grid item lg={12}>
              <TextField variant='outlined' className={classes.loginFormFields} fullWidth label='Location' onChange={e => setLocation(e.target['value'])}></TextField>
            </Grid>
            <Grid item lg={12}>
              <FormControl fullWidth className={classes.loginFormFields}>
                <InputLabel >Job Type</InputLabel>
                <Select
                  labelWidth={20}
                  value={time}
                  onChange={x => {
                    setTime(x.target.value)
                  }}>
                  <MenuItem value='Part Time'>Part Time</MenuItem>
                  <MenuItem value='Full Time'>Full Time</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={12}>
              <TextField variant='outlined' fullWidth className={classes.loginFormFields} label='Vacancies' type='number' onChange={e => setVacancies(e.target['value'])}></TextField>
            </Grid>
            <Grid item lg={12}>
              <Button variant='contained' onClick={e => {
                var empId = JSON.stringify(API.getCookieData()['id']);
                var a: string[] = [
                  jobTitle, jobShortDescription, jobLongDescription, salary, time, vacancies
                ];

                if (!a.every(x => x != null)) return;
                var job = {
                  jobTitle: jobTitle,
                  jobShortDescription: jobShortDescription,
                  jobLongDescription: jobLongDescription,
                  locationText: location,
                  salary: salary,
                  time: time,
                  vacancies: vacancies,
                  userid: empId
                }
                Axios.post(apihost + '/admin/postajob', job).then(x => {
                  console.log(JSON.stringify(x.data));
                  enqueueSnackbar("Job Posted");
                })
              }}>Post Job</Button>
            </Grid>
          </Grid>

        </Card>
      </Grid>

    </Grid>
  </Container>
 

}