import React, { FunctionComponent, useEffect, useState } from 'react';
import Axios from 'axios';
import { API, apihost, Ways } from '../../Helper';
import MaterialTable from 'material-table';
import { Visibility, Message } from '@material-ui/icons';
import { IconButton, CircularProgress, Link, Button, Grid, Badge, Container, ListItem, List, Typography, Toolbar, AppBar } from '@material-ui/core';
import men from './../../assets/men.png';
import { Group } from '@material-ui/icons';
import { createBrowserHistory } from 'history';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { SingleJobCandidatesTitleBar } from './SingleJobCandidatesTitleBar';




const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    

customBadge: {
	backgroundColor: "#41d18c",
	color: "white",
	marginTop:15,
	marginRight: 15,
	width: 10,
	height: 10,
	border: '2px solid rgb(255, 255, 255)',
  
  },

  imageGrid: {

	marginTop: 15,


  },





}),
);



export const AllCandidatesForJob: FunctionComponent = () => {
    const [candidates, setCandidates] = useState();
    const [job, setJob] = useState({})
    useEffect(() => {
        var data = window.location.pathname.split('/');
        console.log(data);
        Axios.get(apihost + '/employer/applicants', { headers: { job: data[3] } }).then(x => {
            if (x.status == 200) {
                console.log(x.data);
                setCandidates(x.data);
                setJob(x.data[0]['job'])
            }
        });

    }, []);
    if (candidates == null) {
        return <div style={{ textAlign: 'center' }}>
            <CircularProgress />
        </div>
    }
    return <div style={{  marginTop: '5%', height: '100vh'}}>
        {/* <SingleJobCandidatesTitleBar /> */}
        


            
                <AppBar position="static" style={{ backgroundColor: '#f7f7f7', paddingLeft: '2%', boxShadow: 'none', marginTop: -120, borderTopLeftRadius: 15, borderTopRightRadius: 15, }}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" aria-label="menu">
                            <Group style={{ fontSize: 35, color: '#616161', }} />
                        </IconButton>
                        <Typography variant="h6" style={{color: '#616161'}}>
                            Candidates For Lead Designer
					</Typography>

                        {/* <Button variant="outlined" className={classes.btnStyle}>Add Job</Button> */}
                    </Toolbar>
                </AppBar>
            
            <Grid item lg={12} xs={6}>


                <MaterialTable
                    style={{ borderBottomLeftRadius: 15, borderBottomRightRadius: 15, boxShadow: 'none', backgroundColor: '#f7f7f7', }}
                    //  title={'Candidates for ' + job['jobTitle']}
                    columns={[
                        { title: 'Id', render: () => <div>111</div> },
                        { field: 'candidate.name', title: 'Name' },
                        { field: 'candidate.email', title: 'Email' },
                        {
                            title: 'Job', render: (data) => <div>
                                <Link href={Ways.adminSingleJob + data['job']['_id']}>{data['job']['jobTitle']}</Link>
                            </div>
                        },
                        { title: 'Location', render: () => <div>Montreal</div> },
                        { title: 'Status', render: () => <div>Refused</div> },
                        {
                            title: 'Action', render: (rd) => {
                                return <Grid direction='row' spacing={1} container >
                                    <Grid item lg={6}>
                                        <Button variant='outlined' color='primary' onClick={() => {
                                            createBrowserHistory().push(Ways.adminCandidateSinglePage);
                                            window.location.reload();
                                        }}>View</Button>

                                    </Grid>
                                    <Grid item lg={6}>
                                        <Button variant='outlined'
                                            onClick={() => {
                                                Axios.get(apihost + '/getchat', { headers: { employer: API.getCookieData()['email'], candidate: rd['email'] } }).then(x => {
                                                    createBrowserHistory().push('/employer/dashboard/chats/' + x.data);
                                                    window.location.reload();
                                                })
                                            }}>
                                            Chat
					</Button>
                                    </Grid>
                                </Grid>
                            }
                        }]}
                    data={candidates}

                />
            </Grid>
       
    </div>
}

export const AllCandidates: FunctionComponent = () => {
	const [candidates, setCandidates] = useState();
	const [job , setJob] = useState({})

	let id :number = 1
    const getID =() =>{
        return id++;
    }
	useEffect(() => {
		var data = window.location.pathname.split('/');
		console.log(data);
		Axios.get(apihost + '/admin/candidates').then(x => {
			if (x.status == 200) {
				console.log(x.data);
				setCandidates(x.data);
			}
		});

	}, []);
	if (candidates == null) {
		return <div style={{ textAlign :'center' }}>
			<CircularProgress />
		</div>
	}
	return <div style={{height: '100vh',}}>


<AppBar position="static" style={{backgroundColor: '#f7f7f7', boxShadow: 'none', borderTopLeftRadius: 8, borderTopRightRadius: 8, paddingTop: 25, paddingLeft: 35,}}>
    <Toolbar>
       <IconButton edge="start" style={{ color: '#515054',}} color="inherit" aria-label="menu">
        <Group style={{fontSize: 35,}} />
      </IconButton>
      <Typography variant="h4" style={{color: '#515054'}} >
        All Candidates
      </Typography>
      
    </Toolbar>
  </AppBar> 
    

	<MaterialTable
	style={{boxShadow: 'none', backgroundColor: '#F7F7F7', borderBottomLeftRadius: 8, borderBottomRightRadius: 8,}}
		title=''
		columns={[
			{ title: 'Id', render: () => <div>{getID()}</div> },
			{ field: 'name', title: 'Name' },
			{ field: 'email', title: 'Email' },
			
			{ title: 'Location', render: () => <div>Montreal</div> },
			{title: 'Status', render: () => 
			<Grid container direction="row">
				<Grid item lg={1}>
				<Badge variant="dot" color="primary" style={{marginRight: -10,}}>

				</Badge>
				</Grid>

				<Grid item lg={2}>
					Refused
				</Grid>
			</Grid>

},
			{
				title: 'Action', render: (rd) => {
					return(
					<List style={{display: 'flex', flexDirection: 'row',}}>
						<ListItem>
						<Button variant='outlined' color='primary' size="small" onClick={() => {
					createBrowserHistory().push(Ways.adminCandidateSinglePage + '/' + rd['_id']);
					window.location.reload();
				}}>View</Button>
						</ListItem>
					
					
					<ListItem style={{marginLeft: -20,}}>
					{/* <Button variant='outlined' color='secondary' size="small" onClick={() => {
					createBrowserHistory().push(Ways.adminCandidateSinglePage + '/' + rd['_id']);
					window.location.reload();
				}}>Chat</Button> */}
				</ListItem>
				</List>
				
					)}
			}]}
		data={candidates}

	/>
	</div>

}


